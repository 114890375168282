<template>
  <div class="contain">
      <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :model="search"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus" style="margin-left: 0px;">
          <!-- @change="searchFun" -->
          <el-date-picker
            
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
   
        <el-form-item label="巡线员" prop="equipmentStatus" style="margin-left: 16px;">
          <el-select
            clearable
            size="mini"
            style="width:170px;"
            v-model="search.userId"
            placeholder="请选择巡线员"
            
          >
          <!-- @change="inquire" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="完成情况" prop="equipmentStatus" style="margin-left: 30px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="search.state"
            placeholder="请选择状态"
            
          >
          <!-- @change="inquire" -->
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus" style="margin-left: 16px;">
          <el-input
          size="mini"
            placeholder="输入任务编号"
            v-model="search.searchText"
            style="width:340px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          type="primary"
          size="mini"
          @click="searchFun"
          style="margin-left:20px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          type="warning"
          @click="reset"
          >重置</el-button
        >
        </el-form-item>
      </el-form>
    </div>
    <div class="modalityCenter">
    
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      height="calc(100vh - 318px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
            <el-table-column
          type="index"
          label="序号"
          align="left"
          width="60"
          >
          </el-table-column>
              <el-table-column prop="taskCode" label="任务编号" align="left" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="networkName" label="巡检区域" align="left" show-overflow-tooltip>
                
              </el-table-column>
              <el-table-column prop="executorPeopleName" label="巡线员" align="left" show-overflow-tooltip>
               
              </el-table-column>
              <el-table-column prop="scheduleStartTime" label="开始时间" align="left"  width="160">
              </el-table-column>
              <el-table-column prop="scheduleEndTime" label="结束时间" align="left"  width="160">
                <template slot-scope="scope">
                {{scope.row.trackStatus !=1 ? scope.row.scheduleEndTime : '--'}} 
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="巡线总时长" align="left" width="170">
                 <template slot-scope="scope">
                <!-- {{(scope.row.scheduleTime/60).toFixed(2)}} <span>分钟</span> -->
                {{parseInt(scope.row.scheduleTime/60/60%24)}} <span>小时</span>
                {{parseInt(scope.row.scheduleTime/60%60)}} <span>分钟</span>
                {{parseInt(scope.row.scheduleTime%60)}} <span>秒</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="lineLength" width="140" label="工作总里程(km)/管线总长度(km)">
                 <template slot-scope="{row}">
                    <div class="examine">{{(row.scheduleDistance/1000).toFixed(2)}}/
                      <span style="color:#2C9EF7"  @click="seeKm(row)">{{(row.jsL&&row.jsL!=-1)?row.jsL:(row.jsL == 0 ? 0 :'查看')}}</span>

                      </div>
                  </template>
              </el-table-column>
              <!-- <el-table-column prop="itemName" label="巡线总里程（km）/管线总长度（km）" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{(scope.row.scheduleDistance/1000).toFixed(2)}} / {{(scope.row.lineLength/1000).toFixed(2)}}
                </template>
              </el-table-column> -->
              <el-table-column prop="itemName" label="已打卡点/应打卡点" width="80" align="left" show-overflow-tooltip>
                 <template slot-scope="scope">
                 {{scope.row.isPushNumber}}/{{scope.row.allPush}}
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="完成情况" align="left" width="80" show-overflow-tooltip>
                 <template slot-scope="{ row }">
                  <el-tag v-if="row.trackStatus == '2'" type='success' size="mini">
                       已完成
                    </el-tag>  
                    <el-tag v-else size="mini" style="color: #E83434;border: 1px solid #F5A7A7;background: #FFF4EB;">
                        未完成
                    </el-tag> 
                    

                 </template>
              </el-table-column>
              <el-table-column prop="eventNumber" label="事件上报数目"  width="100" align="left" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  <div style="color: #1082ff; cursor: pointer" v-if="row.eventNumber > 0" @click="appear(row)">{{row.eventNumber}}</div>
                  <div v-else>{{row.eventNumber}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="itemName" label="操作" align="left" width="210px">
                <template slot-scope="{ row }">
                    <el-button
                        type="primary"
                        size="mini"
                        v-if="$anthButtons.getButtonAuth('lookResult')" 
                        @click="IsOpenFun(row)"
                        >工作轨迹</el-button>
                    <el-button
                        type="primary"
                        size="mini"
                        v-if="row.trackStatus != '2' && $anthButtons.getButtonAuth('completeResult')"
                        @click="editStatus(row)"
                        >设为完成</el-button>
                    </template>
              </el-table-column>
    
    </el-table>

    <div style="text-align:left">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-sizes="[ 10,30, 50, 100]"
        :page-size="search.size"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    </div>
   <map-locus ref="mapLocus"></map-locus>
  </div>
</template>

<script>

import { getUserList,trackPage,setFinish,getLength} from "@/RequestPort/maintenance/task";
import {getTrackPath} from "@/RequestPort/Inspection/inspeTrack";
import mapLocus from "./mapLocus.vue";
import { queryFeatrue } from '../Gis/apis/geo';
import { formatLocation } from '../Gis/utils/geoTool';
export default {
  components: { mapLocus },
  name: "inspePlan",
  data() {
    return {
      excutorDownArray: [],
      networkList:[],
      
      // 状态
      stateList:[
        { label: "未完成", value: 1 },
        { label: "已完成", value: 2 },
      ],
      // monthArray: [],
      DataPicker: [],
      search: {
        current: 1,
        userId: "",
        state:"",
        searchText:"",
        size: 100,
      },
      // status: "",
      // 列表数据
      tableData: [],
      // searchText: "",
      // 总条数
      total: 0,
      choseArr: [],
     
      form: {
        executorPeopleId: "",
        networkId: "",
      },
      
    };
  },
  mounted() {
    this.getOption();
    this.getList();
  },
  methods: {
    seeKm(row){
      getLength({id:row.networkId}).then(res => {
        if(res.code == 200) {
          row.jsL = res.data
        }
      })
      // let coverage=row.coverage
      // let network=row.location
      // let pos = []
      // network.forEach(element => {
      //   pos.push(element[0] + ' ' + element[1])
      // })
      // pos.push(pos[0])
      // let posStr = pos.join(',')
      // let groupLayer = this.$store.getters.groupLayer
      // let group = []
      // if (groupLayer && groupLayer.length > 0) {
      //   groupLayer.forEach(item => {
      //     group.push(item.id)
      //   })
      // }
      

     


      // let layersVue1 = coverage
      // let params = {
      //   SERVICE: 'wfs',
      //   VERSION: '1.0.0',
      //   REQUEST: 'GetFeature',
      //   typeName: 'linqing:view_all_line_q',
      //   outputFormat: 'application/json',
      //   viewparams: `netid:${row.networkId}`,
      //   CQL_FILTER:
      //       "coverage in (" + layersVue1 + ") and INTERSECTS(location,POLYGON((" + posStr + ")))"
      //   }

        
      //   if (!layersVue1) {
      //    params.CQL_FILTER = '1=2'
      //   }  
      //    const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });      
      // queryFeatrue(params).then(res => {//调取管线
      //    loading.close();
      //   // this.total = res.totalFeatures
      //   let f = res.features
      //   let line=[]
      //     f.forEach(item => {
      //       let location=formatLocation(item.properties.location_str,'LineString')      
      //       console.log(location)
            
      //            for(let i=1;i<location.length;i++){
                 
      //              let sub=[Number(location[i-1][0]),Number(location[i-1][1])]
      //              let sub1=[Number(location[i][0]),Number(location[i][1])]
                
      //             line.push([sub1,sub])
      //            }
                 
          
          
      //   })


      //     let py=network
      //    // py.push(py[0])//首尾相接
      //     let allLength=0
      //      line.forEach(item => {//计算长度
      //         var line1 = turf.lineString(item);
      //         var line2 = turf.lineString(py);
      //         var intersects = turf.lineIntersect(line1, line2);  
      //         if(intersects.features.length<1){//没相交是面内
      //             let pn = turf.polygon([py]);                 
      //             let isHave=turf.booleanContains(pn, line1);
      //             if(isHave){                   
      //                 let length = turf.length(line1, {units: 'kilometers'});
      //                 allLength=allLength+length
      //             }                
      //         }else if(intersects.features.length>0){//一条线相交了两次及以上
      //           var line = turf.lineString(item);
      //           var splitter = turf.lineString(py);
      //           var splitline = turf.lineSplit(line, splitter);     
      //           splitline.features.forEach(fea => {     
      //             let feaLine=  fea.geometry.coordinates
      //             let pn = turf.polygon([py]);
      //             let pl = turf.lineString(feaLine);
      //             let isHave=turf.booleanContains(pn, pl);
      //             if(isHave){
      //               let l=turf.lineString(feaLine)
      //               let length = turf.length(l, {units: 'kilometers'});
      //               allLength=allLength+length
      //             }
      //           }); 
      //         }
      //     })
      
      //    allLength>0?row.jsL=allLength.toFixed(3):row.jsL='0'
      // })

    },
    IsOpenFun(row){
      // 五公里
      getTrackPath({id:row.id,precise:5000}).then(res => {
        this.$refs.mapLocus.open(res.data)
      })
    },
    editStatus(row){
        setFinish(row.id).then(res=>{
            this.$message.success(res.msg)
            this.getList();
        })
    },
    getOption(){
      getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
    /**
     * 触发下拉进行查询
     */
    inquire(){
      this.searchFun()
    },
    /**
     * 上报事件
     */
    appear(row){
      // row:JSON.stringify(row)
      // this.$router.push({ path: '/home/incident', query: {id:row.eventId } })
      let chakan = this.$router.resolve({
					path: '/home/incident',      //跳转目标窗口的地址
					query:{id:row.eventId }
				})
				window.open(chakan.href);
    },
    /**
     * 查询事件
     */
    searchFun() {
      this.search.current = 1;
      if (this.DataPicker && this.DataPicker.length > 0) {
        this.search.startDate = this.DataPicker[0];
        this.search.endDate = this.DataPicker[1];
      } else {
        this.search.startDate = null;
        this.search.endDate = null;
      }
      this.getList();
    },
    /**
     * 重置
     */
    reset() {
      this.search = {
        current: 1,
        planName: "",
        size: 100,
      };
      this.DataPicker = [];
      this.getList();
    },
   
  

    /**
     * 查询列表数据
     */
    getList() {
      trackPage(this.search).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          records.forEach(item => {
            item.jsL='-1'
          });
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handleSizeChange(e) {
      this.search.size = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.search.current = e;
      this.getList();
    },
    handleSelectionChange(e) {
      this.choseArr = e;
    },
  },
};
</script>

<style lang="less" scoped>
.contain{
  background-color:transparent;
  padding: 0;
}

// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 10px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
 
}

// 搜索
.searchForm{
  display: flex;
  flex-wrap: wrap;
  //   justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 区域鼠标移上事件
.examine{
 cursor: pointer;
}
// 分页按钮
.el-pagination{
      margin: 15px 0 5px;
}
.view-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.view-table th {
  // background-color: #f5faff;
  font-weight: 400;
  text-align: left;
  width: 180px;
}
.view-table td,
.view-table th {
  border: 1px solid #d1e8fe;
  line-height: 40px;
  padding: 0 15px;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  .left {
    width: 30%;
    margin: 10px 0;
  }
  .right {
    width: 35%;
  }
}
.el-input-group,
.el-input {
  width: initial;
}
.reset {
  margin-left: 10px;
}
.block {
  text-align: right;
}
</style>
