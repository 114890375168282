import axios from '@/common/js/request'
// 获取维修列表
export function trackList(params){ // 巡检轨迹分页列表
    const trackList = axios({
        url: '/zypatrol/track/page',
        method: 'get',
        params
    })
    return trackList
}
// patrol/track/path/get

export function getTrackPath(params){ // 巡线查看轨迹
    const getTrackPath = axios({
        url: '/zypatrol/track/path/get',
        method: 'get',
        params
    })
    return getTrackPath
}


// export function setStatus(data){ // 设置巡检计划状态
//     const setStatus = axios({
//         url: '/zypatrol/plan/config/edit',
//         method: 'post',
//         data
//     })
//     return setStatus
// }